<template>
  <div class="mcenter" style="margin-top: 20vh;">
    <van-toast id="van-toast" />
<!--    <div class="paddbot30 font18" v-if="pay == 'pay'">支付成功</div>-->
    <van-image width="70" height="70" round :src="require('@/assets/img.png')" ></van-image>
    <div class="mcenter mt20 font15">建信服务</div>
  </div>
</template>

<script>
import axios from "axios";
import {get} from "@/utils/request";
import {Toast} from "vant";
import qs from "qs";
export default {
  name: "jxfw",
  data(){
    return{
      urllink: "",
      pay: "",
    }
  },
  mounted() {
    this.pay = "";
    if (this.$route.query.id){
      this.pay = this.$route.query.id
    }
    this.urllink = "";
    this.getlink();
  },
  methods: {
    getlink(){
      let id = "";
      let data = {
        code: "",
        p: "",
      }
      //window.location.href = "https://sixu.work/jxfw?c=lidongyang&p=sj"
      if (window.location.href.indexOf("c=") != -1){
        id = qs.parse(window.location.href.split("#")[0].split("?")[1]).c;
        data.code = id;
        let path = qs.parse(window.location.href.split("#")[0].split("?")[1]).p;
        if (path == "sj"){
          data.p = "pages/program/index/main"
        }
      }
      //console.log("1234",data)
      let url = "https://jz.4lambs.com:443/jxfw/share/sellurl";
      axios.post(url,data).then(res => {
        if (res != null && res.data != null && res.data.content != null){
          this.urllink = res.data.content;
          console.log("link---",this.urllink)
          //window.location.href = res.data.content
        }else {
          Toast("获取失败")
        }
      })
    },
  },
}
</script>

<style scoped>

</style>